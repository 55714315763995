.react-plot-container {
    display: flex;
    justify-content: center;
    margin: auto;
    padding-bottom: 2rem;
}

.modebar{
    display: none !important;
}

.nsewdrag.drag{
    cursor: default !important;
}

@media screen and (max-width: 1000px) {
    /* .user-select-none.svg-container {
        zoom: 0.55
    } */
    .scroll-helper {
        position: fixed;
        left: 10vw;
        top: 3rem;
        height: 100vh;
        width: 80vw;
        z-index: 2 !important;
    }

    .historic-scroll-helper {
        position: fixed;
        left: 10vw;
        top: 17rem;
        height: 80vh;
        width: 80vw;
        z-index: 2 !important;
    }

    .plot-wrapper p {
        align-self: center !important;
        inline-size: 350px;
        color: #ff6b12;
        font-weight: bold;
        text-overflow: ellipsis;
    }
}

.rotate-tooltip {
    display: none;
}

.title-wrapper {
    color: #ff6b12;
    display: flex;
    justify-content: center;
    text-align: center;
}

.title-wrapper h4 {
    margin-top: 2rem;
}

.plot-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: left;
}

.plot-wrapper p {
    align-self: flex-start;
    color: #ff6b12;
    font-weight: bold;
}

.date-input-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
}