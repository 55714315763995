.navbar-container {
    background-color: #141414;
    max-width: 500px;
    padding-top: 1rem;
    margin: auto;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    margin-top: -.5rem;
}

.navbar-link {
    color: #f77f00;
    text-decoration: none;
    margin: 0.5rem 1rem;
    font-weight: bold;
    font-size: 1.3rem;
}

.navbar-link:hover {
    transition: 150ms;
    color: #fcbf49;
}