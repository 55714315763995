.description {
    text-align: justify;
    color: white;
    margin: auto;
    max-width: 500px;
    padding: 0px 10px;
}

.description-wrapper {
    display: flex;
}

.description-header {
    color:#f77f00;
    margin: 2rem 0rem;
}

a {
    color: #ADD8E6;
    text-decoration: underline;
}

.site-links > li {
    margin: 1.5rem;
}